/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
import MDXLayout from "/builds/thiagovilla/thiagovilla.com/src/templates/blog";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "Buffer and streams"), "\n", React.createElement(_components.p, null, "Control flow"), "\n", React.createElement(_components.p, null, "Events"), "\n", React.createElement(_components.p, null, "Child processes"), "\n", React.createElement(_components.p, null, "Error handling"));
}
function MDXContent(props = {}) {
  return React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props));
}
export default MDXContent;
